<template>
	<Modal
		:is_modal="is_modal"
		:option="modal_option"
		:width="'560px'"
		:height="'480px'"

		@cancel="$emit('cancel')"
		@close="$emit('cancel')"
		@click="click"
	>
		<div
			slot="modal-content"
		>
			<div>
				<input
					v-model="item_search.agency_name"
					class="input-box-inline mr-10"
					placeholder="영업점 명을 입력하세요"
				/>
				<button
					@click="getUpper"
					class="box pa-5-10 bg-base"
				>조회</button>
			</div>
			<table
				class="table mt-10"
			>
				<thead>
				<tr>
					<th>구분</th>
					<th>영업점 명</th>
					<th>선택</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(upper, index) in items_upper"
					:key="'upper_' + index"
					:class="{on: upper.uid == item_upper_select.uid}"
				>
					<td>{{ upper.agency_type_name}}</td>
					<td class="text-left">{{ upper.agency_name}}</td>
					<td><button @click="selectUpper(upper)" class="btn-blue pa-5-10">선택</button></td>
				</tr>
				</tbody>
			</table>
		</div>
	</Modal>
</template>

<script>
import Modal from "../../components/Modal";
export default {
	name: 'AgencySearch'
	, props: ['agency_type']
	, components: { Modal }
	, data: function(){
		return {
			item_search: {
				agency_name: ''
			}
			, items_upper: []
			, item_upper_select: {

			}
			, is_modal: true
			, modal_option: {
				top: true
				,title: '소속 영업점 조회'
				,bottom: true
			}
		}
	}

	, methods: {
		getUpper: async function(){
			try{
				this.item_upper_select = {}
				const result = await this.$request.init({
					method: 'get'
					,url: 'admin/getUpper'
					,data: {
						agency_type: this.agency_type
						, agency_name: this.item_search.agency_name
					}
				})
				if(result.success){
					this.items_upper = result.data.result
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, selectUpper: function(upper){
			this.item_upper_select = upper
		}
		, click: function(){
			this.$emit('click', this.item_upper_select)
		}
	}
	, created() {

	}
}
</script>